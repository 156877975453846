import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer class="bg-surface-primary p-3">
            <div class="container-fluid">
                <div>
                    <div>
                        <ul className="nav justify-content-center">
                            <li className='nav-item'>
                                <Link className='nav-link px-2 text-muted' to="/terms-and-conditions">Terms</Link>
                            </li>
                            <li style={{
                                width: '7px',
                                height: '7px',
                                backgroundColor: 'rgba(107, 123, 147,1)',
                                borderRadius: '50%',
                                margin: '16px 5px 0 8px'
                            }}></li>
                            <li className='nav-item'>
                                <Link className='nav-link px-2 text-muted' to="/privacy-policy">Privacy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
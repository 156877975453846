import React from "react";

import { useEffect } from "react";
import {
  useDyteClient,
  DyteProvider,
  useDyteSelector,
} from "@dytesdk/react-web-core";
import { DyteMeeting } from "@dytesdk/react-ui-kit";

import { useDyteMeeting } from "@dytesdk/react-web-core";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const MeetingUI = () => {
  const [meeting, initMeeting] = useDyteClient();
  const navigate = useNavigate();

  useEffect(() => {
    initMeeting({
      authToken: localStorage.getItem("meetingToken"),
      defaults: {
        audio: false,
        video: false,
      },
    }).catch((error) => {
      console.log(error);
      navigate("/meetings");
      toast.error(
        "Meeting is currently Inactive, you are not allowed to join. Redirecting to the meetings page..."
      );
    });
  }, []);

  return (
    <DyteProvider value={meeting}>
      <MyMeetingUI />
    </DyteProvider>
  );
};

export default MeetingUI;

const MyMeetingUI = () => {
  const navigate = useNavigate();
  const roomState = useDyteSelector((m) => m.self.roomState);

  console.log(roomState);

  if (roomState === "left" || roomState === "ended") {
    setTimeout(() => {
      navigate("/meetings");
    }, 3000);
  }

  const { meeting } = useDyteMeeting();

  return (
    <div style={{ height: "100vh" }}>
      <DyteMeeting mode="fill" meeting={meeting} showSetupScreen={false} />
    </div>
  );
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = "https://www.termsfeed.com/live/157d8d04-3a95-44af-8cb0-900afeff3e3e";
    }, [navigate]);

    return null;
};


export default TermsAndConditions;

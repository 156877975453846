import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/meeting.css";

import joinIcon from "../assets/join_icon.png";
import toast from "react-hot-toast";
import illustration from "../assets/Illustration.png";
import SideNav from "../components/SideNav";
import TopNav from "../components/TopNav";

import avatarFinal from "../assets/avatar_final.png";

import { useContext } from "react";

import { PageContext } from "../MyContext";
import Footer from "../components/Footer";
import BookingRecordingModal from "../components/BookingRecordingModal";

const Meetings = () => {
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isPast, setIsPast] = useState(false);

  const navigate = useNavigate();

  const { thisPage, setThisPage } = useContext(PageContext);

  const options = {
    method: "GET",
    headers: {
      accept: "*/*",
      jwtToken: localStorage.getItem("token"),
    },
  };

  useEffect(() => {
    if (localStorage.getItem("userId") === null) {
      navigate("/");
    }

    setThisPage("meetings");
  }, []);

  useEffect(() => {
    if (!isPast) {
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/tutor/booking/session/meetings?userId=${localStorage.getItem(
          "userId"
        )}&page=${currentPage}&size=10`,
        options
      ).then(async (response) => {
        let resp = await response.json();

        console.log(resp);

        if (resp.upcoming) {
          setTotalItems(resp.totalItems);

          setUpcomingBookings(resp.upcoming);
        }

        if (pageCount.length === 0) {
          let num = resp.totalPages;

          for (let i = 0; i < num; i++) {
            pageCount.push(i + 1);
          }
        }
      });
    } else {
      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/tutor/booking/session/meetings?userId=${localStorage.getItem(
          "userId"
        )}&isPast=true&page=${currentPage}&size=10`,
        options
      ).then(async (response) => {
        let resp = await response.json();

        console.log(resp);

        if (resp.past) {
          setTotalItems(resp.totalItems);

          setUpcomingBookings(resp.past);
        }

        if (pageCount.length === 0) {
          let num = resp.totalPages;

          for (let i = 0; i < num; i++) {
            pageCount.push(i + 1);
          }
        }
      });
    }
  }, [currentPage, isPast]);

  function joinMeeting(params) {
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/tutor/booking/get-meeting-token?userId=${localStorage.getItem(
        "userId"
      )}&bookingId=${params}&role=${localStorage.getItem("role")}`,
      options
    ).then(async (response) => {
      let resp = await response.json();

      console.log(resp);

      if (resp.success) {
        localStorage.setItem("meetingToken", resp.data.token);

        navigate(`/meetings/${params}`);
      } else {
        toast.error(resp.message);

        document.querySelectorAll(".button-join").forEach((val) => {
          val.lastChild.innerText = "Join Meeting";
        });
      }
    });
  }

  const handleMarkInactive = async (bookingId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meeting/set-active-status?userId=${userId}&bookingId=${bookingId}&isActive=false`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            jwtToken: token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to mark meeting as inactive");
      }

      const data = await response.json();
      toast.success("Meeting marked as inactive");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleMarkActive = async (bookingId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meeting/set-active-status?userId=${userId}&bookingId=${bookingId}&isActive=true`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            jwtToken: token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to mark meeting as active");
      }

      const data = await response.json();
      toast.success("Meeting marked as active");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleAddRecordingLink = (bookingId) => {
    setSelectedBookingId(bookingId);
    setShowModal(true);
  };

  return (
    <>
      <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <SideNav />

        <div class="h-screen flex-grow-1 overflow-y-lg-auto">
          <TopNav />

          <main class="py-6 bg-surface-secondary h-100">
            <div class="container-fluid">
              <div
                style={{
                  padding: "1rem 1.5rem",
                  backgroundColor: "white",
                  marginBottom: "2rem",
                  borderRadius: "7px",
                }}
              >
                <h4>{isPast ? "Past Meetings" : "Upcoming Meetings"}</h4>
              </div>

              <div class="card shadow border-0 mb-7">
                <div class="card-header" style={{ padding: "0 1.5rem" }}>
                  <ul class="nav nav-tabs  overflow-x border-0">
                    <li class="nav-item ">
                      <a
                        className={`nav-link cursor-pointer ${
                          !isPast ? "active" : "font-regular"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          if (isPast) {
                            setIsPast(false);
                            setUpcomingBookings([]);
                            setPageCount([]);
                            setCurrentPage(0);
                            setTotalItems(0);
                          }
                        }}
                      >
                        Upcoming Meetings
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        className={`nav-link cursor-pointer ${
                          isPast ? "active" : "font-regular"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!isPast) {
                            setIsPast(true);
                            setUpcomingBookings([]);
                            setPageCount([]);
                            setCurrentPage(0);
                            setTotalItems(0);
                          }
                        }}
                      >
                        Past Meetings
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="table-responsive">
                  <table class="table table-hover table-nowrap">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col ">
                          {localStorage.getItem("role") === "STUDENT"
                            ? "Tutor"
                            : "Student"}
                        </th>

                        <th scope="col">Subject</th>
                        <th scope="col">Booking ID</th>
                        <th scope="col">Date & Time</th>
                        <th scope="col">Timezone</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Amount</th>
                        {localStorage.getItem("role") === "ADMIN" && (
                          <th scope="col">Admin</th>
                        )}
                        <th scope="col">Type</th>

                        {!isPast ? (
                          <th></th>
                        ) : (
                          <th scope="col">Meeting Status</th>
                        )}
                        {localStorage.getItem("role") === "ADMIN" && (
                          <th scope="col">Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingBookings.length !== 0 ? (
                        upcomingBookings.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  alt="..."
                                  src={
                                    value.studentProfileAvatar
                                      ? value.studentProfileAvatar
                                      : avatarFinal
                                  }
                                  class="avatar avatar-sm rounded-circle me-2"
                                />
                                <a class="text-heading font-semibold" href="#">
                                  {localStorage.getItem("role") === "STUDENT"
                                    ? value.tutorName
                                    : value.studentName}
                                </a>
                              </td>
                              <td>{value.subjectName}</td>
                              <td>
                                <a class="text-heading font-semibold" href="#">
                                  {value.bookingId}
                                </a>
                              </td>
                              <td>
                                {localStorage.getItem("role") === "TUTOR"
                                  ? value.tutorAppointmentDate +
                                    " at " +
                                    value.tutorTimeSlot.startTime.slice(0, 5)
                                  : value.appointmentDate +
                                    " at " +
                                    value.timeSlot.startTime.slice(0, 5)}
                              </td>
                              <td>
                                {localStorage.getItem("role") === "STUDENT"
                                  ? value.tutorTimeZone
                                  : value.studentTimeZone}
                              </td>
                              <td>{value.paymentDetails.lessonDuration}</td>
                              <td>
                                {"$ " + value.paymentDetails.bookingAmount}
                              </td>
                              {localStorage.getItem("role") === "ADMIN" && (
                                <td scope="col">
                                  {value.adminName ? value.adminName : "Admin"}
                                </td>
                              )}
                              <td>
                                <button
                                  className="btn btn-sm btn-light"
                                  style={{ minWidth: "94.89px" }}
                                >
                                  {value.lessonType}
                                </button>
                              </td>

                              {!isPast ? (
                                <td class="text-end">
                                  <button
                                    href="#"
                                    class="btn btn-sm btn-neutral button-join shadow-none"
                                    style={{ minWidth: "141.39px" }}
                                    onClick={(e) => {
                                      e.target.lastChild.innerText =
                                        "Joining...";
                                      joinMeeting(value.bookingId);
                                    }}
                                  >
                                    <img
                                      src={joinIcon}
                                      alt=""
                                      style={{
                                        marginRight: "0.3rem",
                                        pointerEvents: "none",
                                        maxWidth: "initial",
                                      }}
                                    />{" "}
                                    <span style={{ pointerEvents: "none" }}>
                                      Join Meeting
                                    </span>
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <a
                                    href="#"
                                    className="btn btn-sm btn-success"
                                  >
                                    {value.bookingStatus}
                                  </a>
                                </td>
                              )}
                              {localStorage.getItem("role") === "ADMIN" && (
                                <td scope="col">
                                  <div className="dropdown">
                                    <i
                                      className="bi bi-three-dots"
                                      style={{
                                        fontSize: "20px",
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                      id="dropdownMenuButton"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    ></i>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleMarkInactive(value.bookingId)
                                          }
                                        >
                                          Mark Meeting In-Active
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleMarkActive(value.bookingId)
                                          }
                                        >
                                          Mark Meeting Active
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleAddRecordingLink(
                                              value.bookingId
                                            )
                                          }
                                        >
                                          Add Recording Link
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={
                              localStorage.getItem("role") === "ADMIN" ? 11 : 8
                            }
                            style={{
                              paddingTop: "4rem",
                              paddingBottom: "4rem",
                              textAlign: "center",
                            }}
                          >
                            <img src={illustration} alt="" />{" "}
                            <h5 style={{ marginTop: "1rem" }}>
                              No meetings today
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className="card-footer border-0 py-5 d-flex align-items-center justify-content-center"
                  style={{ gap: "1rem", flexWrap: "wrap" }}
                >
                  <span className="text-muted text-sm">
                    Showing {upcomingBookings.length} of {totalItems} Entries
                  </span>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      {/* Previous button */}
                      <li className="page-item">
                        <a
                          className="page-link"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (currentPage > 0) {
                              setCurrentPage((prev) => prev - 1);
                            }
                          }}
                        >
                          <i className="bi bi-chevron-left"></i>
                        </a>
                      </li>

                      {/* Start of pagination logic */}
                      {pageCount.map((value, index) => {
                        // Display first few pages, last few pages, and current page neighbors
                        if (
                          index === 0 ||
                          index === pageCount.length - 1 || // Always show last page
                          (index >= currentPage - 1 && index <= currentPage + 1) // Show pages around current page
                        ) {
                          return (
                            <li
                              key={index}
                              className={`page-item ${
                                index === currentPage ? "myActive" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (index !== currentPage) {
                                    setCurrentPage(index);
                                  }
                                }}
                              >
                                {index + 1}
                              </a>
                            </li>
                          );
                        }

                        // Add ellipsis after first few pages and before last few pages
                        if (
                          (index === currentPage - 2 && currentPage > 3) || // Before current page
                          (index === currentPage + 2 &&
                            currentPage < pageCount.length - 4) // After current page
                        ) {
                          return (
                            <li key={index} className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          );
                        }

                        // Return null for other hidden pages
                        return null;
                      })}

                      {/* Next button */}
                      <li className="page-item">
                        <a
                          className="page-link"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (currentPage < pageCount.length - 1) {
                              setCurrentPage((prev) => prev + 1);
                            }
                          }}
                        >
                          <i className="bi bi-chevron-right"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
      {selectedBookingId && (
        <BookingRecordingModal
          bookingId={selectedBookingId}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default Meetings;

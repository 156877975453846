import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = "https://www.termsfeed.com/live/5553996c-111a-4a3a-af2c-3f6f68e06aa8";
    }, [navigate]);

    return null; 
};


export default PrivacyPolicy;

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";

const BookingRecordingModal = ({ bookingId, showModal, setShowModal }) => {
  const [recordingLink, setRecordingLink] = useState("");

  const handleAddRecordingLink = async (bookingId, recordingLink) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meeting/add-recording-link?userId=${userId}&bookingId=${bookingId}&recordingLink=${recordingLink}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            jwtToken: token,
          },
        }
      );

      if (!response.ok) {
        toast.error("Failed to add recording link");
        throw new Error("Failed to add recording link");
      }

      const data = await response.json();
      toast.success("Recording link added successfully!");
      setShowModal(false);
      setRecordingLink("");
    } catch (error) {
      setRecordingLink("");
      toast.error(error.message);
    }
  };

  const handleSubmit = () => {
    handleAddRecordingLink(bookingId, recordingLink);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add Recording Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="recordingLink">
          <Form.Label>Recording URL</Form.Label>
          <Form.Control
            type="text"
            value={recordingLink}
            onChange={(e) => setRecordingLink(e.target.value)}
            placeholder="Enter recording link"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="success" size="sm" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BookingRecordingModal;
